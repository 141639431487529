import React from 'react';
import SectionTitle from '../../../components/shared/section-title';
import PortableText from '../../../components/shared/portableText';
import {
  BodySectionWrap,
  BodyTitleContainer,
  BodyContentContainer,
  BodyCopy,
  Accent,
  DetailsWrap,
  InfoItem
} from './body.stc';

const Body = ({ title, subhead, copy, image, titleAlign, client, category, year }) => {
  return (
    <BodySectionWrap>
      <BodyContentContainer>
        <BodyTitleContainer>
          <SectionTitle
            title={title}
            subtitle={subhead}
            titleAlign={titleAlign}
          />
        </BodyTitleContainer>
        <Accent fluid={image} />
        {copy && (
          <BodyCopy>
            <PortableText blocks={copy} />
          </BodyCopy>
        )}
      </BodyContentContainer>
    </BodySectionWrap>
  );
};

export default Body;
